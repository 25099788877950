
        var result = require("!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./css-props.css");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    