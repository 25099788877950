// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select.blue {
  background: var(--ucd-blue-50, #CCE0F3);
  color: var(--ucd-blue, #022851);
  border: 1px solid var(--ucd-blue-50, #CCE0F3);
}
`, "",{"version":3,"sources":["webpack://./dev/css/forms.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,+BAA+B;EAC/B,6CAA6C;AAC/C","sourcesContent":["select.blue {\n  background: var(--ucd-blue-50, #CCE0F3);\n  color: var(--ucd-blue, #022851);\n  border: 1px solid var(--ucd-blue-50, #CCE0F3);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
