// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l-container--narrow-desktop {
  width: 92%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .l-container--narrow-desktop {
    width: 68%;
  }
}
[hidden] {
  display: none !important;
}
.flex {
  display: flex;
}
.flex--wrap {
  flex-wrap: wrap;
}
.flex--align-center {
  align-items: center;
}
.u-width-100 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./dev/css/layout.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;AACb","sourcesContent":[".l-container--narrow-desktop {\n  width: 92%;\n  margin: 0 auto;\n}\n@media (min-width: 768px) {\n  .l-container--narrow-desktop {\n    width: 68%;\n  }\n}\n[hidden] {\n  display: none !important;\n}\n.flex {\n  display: flex;\n}\n.flex--wrap {\n  flex-wrap: wrap;\n}\n.flex--align-center {\n  align-items: center;\n}\n.u-width-100 {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
