// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.italic {
  font-style: italic;
}
.bold {
  font-weight: 700;
}
a.bold-link {
  color: var(--ucd-blue-80, #13639E);
  text-decoration: none;
  font-weight: var(--font-weight--bold, 700);
}
a.bold-link:hover {
  text-decoration: underline;
  color: var(--ucd-blue-80, #13639E);
}
.font-size--small {
  font-size: var(--font-size--small, 0.875rem);
}
.caption {
  font-size: var(--font-size--small, 0.875rem);
  color: var(--ucd-black-70, #4C4C4C);
  font-weight: 400;
}
.admin-badge {
  background: var(--ucd-black-20, #E5E5E5);
  font-weight: 700;
  color: var(--ucd-black-70, #4C4C4C);
  font-size: var(--font-size--small, 0.875rem);
  display: inline-flex;
  padding: 0 .25rem;
}
`, "",{"version":3,"sources":["webpack://./dev/css/text.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kCAAkC;EAClC,qBAAqB;EACrB,0CAA0C;AAC5C;AACA;EACE,0BAA0B;EAC1B,kCAAkC;AACpC;AACA;EACE,4CAA4C;AAC9C;AACA;EACE,4CAA4C;EAC5C,mCAAmC;EACnC,gBAAgB;AAClB;AACA;EACE,wCAAwC;EACxC,gBAAgB;EAChB,mCAAmC;EACnC,4CAA4C;EAC5C,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":[".italic {\n  font-style: italic;\n}\n.bold {\n  font-weight: 700;\n}\na.bold-link {\n  color: var(--ucd-blue-80, #13639E);\n  text-decoration: none;\n  font-weight: var(--font-weight--bold, 700);\n}\na.bold-link:hover {\n  text-decoration: underline;\n  color: var(--ucd-blue-80, #13639E);\n}\n.font-size--small {\n  font-size: var(--font-size--small, 0.875rem);\n}\n.caption {\n  font-size: var(--font-size--small, 0.875rem);\n  color: var(--ucd-black-70, #4C4C4C);\n  font-weight: 400;\n}\n.admin-badge {\n  background: var(--ucd-black-20, #E5E5E5);\n  font-weight: 700;\n  color: var(--ucd-black-70, #4C4C4C);\n  font-size: var(--font-size--small, 0.875rem);\n  display: inline-flex;\n  padding: 0 .25rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
