// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u-space-py-responsive--huge {
  padding-top: var(--spacer--large, 2rem);
  padding-bottom: var(--spacer--large, 2rem);
}
.u-space-py-responsive--medium-2x {
  padding-top: var(--spacer--medium, 1.5rem);
  padding-bottom: var(--spacer--medium, 1.5rem);
}
@media (min-width: 768px) {
  .u-space-py-responsive--huge {
    padding-top: var(--spacer--huge, 4rem);
    padding-bottom: var(--spacer--huge, 4rem);
  }
  .u-space-py-responsive--medium-2x {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.u-space-pb--medium-2x {
  padding-bottom: 3rem !important;
}
.u-space-pt--medium-2x {
  padding-top: 3rem !important;
}
`, "",{"version":3,"sources":["webpack://./dev/css/spacing.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,0CAA0C;AAC5C;AACA;EACE,0CAA0C;EAC1C,6CAA6C;AAC/C;AACA;EACE;IACE,sCAAsC;IACtC,yCAAyC;EAC3C;EACA;IACE,iBAAiB;IACjB,oBAAoB;EACtB;AACF;;AAEA;EACE,+BAA+B;AACjC;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".u-space-py-responsive--huge {\n  padding-top: var(--spacer--large, 2rem);\n  padding-bottom: var(--spacer--large, 2rem);\n}\n.u-space-py-responsive--medium-2x {\n  padding-top: var(--spacer--medium, 1.5rem);\n  padding-bottom: var(--spacer--medium, 1.5rem);\n}\n@media (min-width: 768px) {\n  .u-space-py-responsive--huge {\n    padding-top: var(--spacer--huge, 4rem);\n    padding-bottom: var(--spacer--huge, 4rem);\n  }\n  .u-space-py-responsive--medium-2x {\n    padding-top: 3rem;\n    padding-bottom: 3rem;\n  }\n}\n\n.u-space-pb--medium-2x {\n  padding-bottom: 3rem !important;\n}\n.u-space-pt--medium-2x {\n  padding-top: 3rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
