// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grecaptcha-badge { visibility: hidden; }
.user-logged-out ucd-theme-quick-links {
  --ucd-theme-quick-links-icon-bg-color: var(--ucd-blue-70, #73ABDD);
}
`, "",{"version":3,"sources":["webpack://./dev/css/site.css"],"names":[],"mappings":"AAAA,oBAAoB,kBAAkB,EAAE;AACxC;EACE,kEAAkE;AACpE","sourcesContent":[".grecaptcha-badge { visibility: hidden; }\n.user-logged-out ucd-theme-quick-links {\n  --ucd-theme-quick-links-icon-bg-color: var(--ucd-blue-70, #73ABDD);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
