// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg--blue-30 {
  background-color: var(--ucd-blue-30,#ebf3fa);
}
.blue-70 {
  color: var(--ucd-blue-70, #73ABDD);
}
.black {
  color: var(--black, #000) !important;
}
.gray {
  color: var(--gray, #4c4c4c) !important;
}
`, "",{"version":3,"sources":["webpack://./dev/css/color.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C;AACA;EACE,kCAAkC;AACpC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,sCAAsC;AACxC","sourcesContent":[".bg--blue-30 {\n  background-color: var(--ucd-blue-30,#ebf3fa);\n}\n.blue-70 {\n  color: var(--ucd-blue-70, #73ABDD);\n}\n.black {\n  color: var(--black, #000) !important;\n}\n.gray {\n  color: var(--gray, #4c4c4c) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
