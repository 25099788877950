// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  padding: var(--spacer--medium-2x, 3rem) 0;
  border-bottom: 2px solid var(--ucd-black-20, #E5E5E5);
}
.page-header--mb {
  margin-bottom: 1rem;
}
.page-header__wrapper {
  width: 92%;
  margin: 0 auto;
}
.page-header__wrapper--flex {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .page-header__wrapper {
    width: 86%;
  }
  .page-header--mb {
    margin-bottom: 2.5rem;
  }
}
.page-header__title {
  display: flex;
  align-items: center;
}
.page-header__title h1 {
  margin: 0;
}
.page-header__title app-icon {
  margin-right: var(--spacer--medium, 1.5rem);
  --app-icon-size: var(--spacer--medium-2x, 3rem);
}
.page-header__title + .page-header__description {
  margin-top: var(--spacer--medium, 1.5rem);
}
.page-header__title + .page-header__subtitle {
  margin-top: var(--spacer, 1rem);
}
.page-header__subtitle + .page-header__description {
  margin-top: var(--spacer, 1rem);
}
.page-header__links {
  margin-top: var(--spacer--medium, 1.5rem);
}
`, "",{"version":3,"sources":["webpack://./dev/css/page-header.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,qDAAqD;AACvD;AACA;EACE,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,qBAAqB;EACvB;AACF;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,SAAS;AACX;AACA;EACE,2CAA2C;EAC3C,+CAA+C;AACjD;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,+BAA+B;AACjC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,yCAAyC;AAC3C","sourcesContent":[".page-header {\n  padding: var(--spacer--medium-2x, 3rem) 0;\n  border-bottom: 2px solid var(--ucd-black-20, #E5E5E5);\n}\n.page-header--mb {\n  margin-bottom: 1rem;\n}\n.page-header__wrapper {\n  width: 92%;\n  margin: 0 auto;\n}\n.page-header__wrapper--flex {\n  display: flex;\n  justify-content: space-between;\n}\n@media (min-width: 768px) {\n  .page-header__wrapper {\n    width: 86%;\n  }\n  .page-header--mb {\n    margin-bottom: 2.5rem;\n  }\n}\n.page-header__title {\n  display: flex;\n  align-items: center;\n}\n.page-header__title h1 {\n  margin: 0;\n}\n.page-header__title app-icon {\n  margin-right: var(--spacer--medium, 1.5rem);\n  --app-icon-size: var(--spacer--medium-2x, 3rem);\n}\n.page-header__title + .page-header__description {\n  margin-top: var(--spacer--medium, 1.5rem);\n}\n.page-header__title + .page-header__subtitle {\n  margin-top: var(--spacer, 1rem);\n}\n.page-header__subtitle + .page-header__description {\n  margin-top: var(--spacer, 1rem);\n}\n.page-header__links {\n  margin-top: var(--spacer--medium, 1.5rem);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
