// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.prefixed-icon {
  display: inline-flex;
  align-items: center;
}
a.prefixed-icon app-icon {
  margin-right: var(--spacer--small, 1rem);
  --app-icon-size: var(--font-size, 1rem);
  color: var(--ucd-blue-70, #73ABDD);
}
`, "",{"version":3,"sources":["webpack://./dev/css/icons.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,wCAAwC;EACxC,uCAAuC;EACvC,kCAAkC;AACpC","sourcesContent":["a.prefixed-icon {\n  display: inline-flex;\n  align-items: center;\n}\na.prefixed-icon app-icon {\n  margin-right: var(--spacer--small, 1rem);\n  --app-icon-size: var(--font-size, 1rem);\n  color: var(--ucd-blue-70, #73ABDD);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
